// import { SWRConfig } from "swr";
import { useState } from "react";
import Head from "next/head";
import "@fontsource/cormorant-garamond";
import "@fontsource/eb-garamond";
// import { SessionProvider } from "next-auth/react";
import { headerContext, bannerContext } from "../services/context";
import Script from "next/script";
import "../styles/globals.css";
import { createTheme, colors, ThemeProvider } from "@mui/material";
import {GoogleAnalytics} from "@next/third-parties/google";


const theme = createTheme({
  typography: {
    fontFamily: ["Cormorant Garamond", "serif", "EB Garamond", "serif"].join(
      ",",
    ),
  },
  palette: {
    primary: {
      main: "#61826c",
      secondary: colors.blue[800],
      light: colors.blue[400],
    },
    secondary: {
      main: "#809B89",
      secondary: colors.green[900],
      light: colors.green[400],
    },
    black: "#000000",
  },
});

function MyApp({ Component, pageProps }) {
  const [headerInfo, setHeaderInfo] = useState({
    accountStatus: "incomplete",
    legacyStatus: null,
    isAdmin: false,
  });

  

  const getLayout = Component.getLayout || ((page) => page);
  return (
    <>
      <Head>  
        <meta name="referrer" content="origin"></meta>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta charSet="utf-8" />
        <meta name="google-site-verification" content="TmjhkpDA1lVTvFhYft0WjTPo9pys10X39ZehSfyOuTc" />

        <title>The Boho Market</title>
      </Head>
      <GoogleAnalytics gaId="G-D0JT6CYT2W" />
      <Script 
        src="https://apis.google.com/js/platform.js?onload=init"
        async
        defer
      ></Script>
      <Script
        async
        src="https://upload-widget.cloudinary.com/global/all.js"
        type="text/javaScript"
      ></Script>
      <ThemeProvider theme={theme}>
        
          <headerContext.Provider value={{ headerInfo, setHeaderInfo }}>
            {getLayout(<Component {...pageProps} />)}
          </headerContext.Provider>
        
      </ThemeProvider>
    </>
  );
}

export default MyApp;
